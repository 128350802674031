<template>
  <div class="home">
    <div class="userinfo">
      <div class="nameList">
        <div class="name">
          <!-- <span class="lable">姓名：</span> -->
          <span class="value">{{ userInfo.name }}</span>
        </div>
        <div class="statusTime status">
          受教育：{{ userInfo.educations_time }} 学时
        </div>
        <div class="status" v-show="userInfo.status == 1">已进场</div>
        <div class="status warn" v-show="userInfo.status == 2">已退场</div>
      </div>
      <div class="userinfoList">
        <div class="sex">
          <span class="lable">性别：</span>
          <span class="value">{{ userInfo.sex }}</span>
        </div>
        <div class="workType">
          <span class="lable">工种：</span>
          <span class="value">{{ userInfo.workType }}</span>
        </div>
        <div class="age">
          <span class="lable">出生日期：</span>
          <span class="value">{{ userInfo.age }}</span>
        </div>
        <div class="age">
          <span class="lable">年龄：</span>
          <span class="value">{{ userInfo.ageNum }}</span>
        </div>
      </div>
    </div>
    <div class="projectInfo">
      <van-field label="项目：" :value="projectInfo.name" readonly />
      <van-field label="劳务公司：" :value="projectInfo.lwgs" readonly />
      <van-field label="劳务班组：" :value="projectInfo.lwbz" readonly />
      <van-field label="进场日期：" :value="projectInfo.jcrq" readonly />
      <van-field label="退场日期：" :value="projectInfo.tcrq" readonly />
    </div>

    <div class="more">
      <van-cell is-link title="培训记录" value="查看" @click="gotoPX" />
      <!-- <van-cell is-link title="工人履历" value="查看" @click="gotoLL" /> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { Toast } from "vant";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      userInfo: {
        name: "***",
        sex: "*",
        age: "**",
        ageNum: null,
        workType: "***",
        status: 2,
        educations_time: 0,
      },
      projectInfo: {
        name: "***",
        lwgs: "******",
        lwbz: "******",
        jcrq: "******",
        tcrq: "******",
      },
      initData: null,
    };
  },
  methods: {
    //跳转培训
    gotoPX() {
      if (this.initData === null || this.initData.education.length < 1) {
        Toast.fail({
          message: "无教育记录",
          position: "top",
          forbidClick: true,
        });
        return;
      }
      this.$router.push({
        name: "studyList",
        params: { data: this.initData.education },
      });
      sessionStorage.setItem(
        "education",
        JSON.stringify(this.initData.education)
      );
    },
    //跳转工人项目履历
    gotoLL() {
      console.log("点击了");
      this.$router.push("/projectList");
    },
    getQueryString(key) {
      const href = window.location.href;
      const reg = new RegExp(key + "=([^&^#]+)");
      const march = reg.exec(href);
      if (march != null) {
        const value = march[1];
        return value;
      }
      return null;
    },
  },

  mounted() {
    //从地址栏截取参数
    console.log(this.getQueryString("keys"));
    if (this.getQueryString("keys") == null) {
      return;
    }
    let keys = this.getQueryString("keys");
    let workerinfo =JSON.parse(sessionStorage.getItem("workerinfo"));
    if (workerinfo) {
      if (workerinfo.id == keys) {
        this.userInfo.name = workerinfo.name;
        this.userInfo.sex = workerinfo.gender;
        this.userInfo.age = workerinfo.birthday;
        this.userInfo.workType = workerinfo.work_type_name;
        this.userInfo.status = workerinfo.status;
        let currentYear = new Date().getFullYear();
        this.userInfo.ageNum = workerinfo.birthday.split("-")[0]
          ? currentYear - workerinfo.birthday.split("-")[0]
          : null;
        this.projectInfo.name = workerinfo.dept_name;
        this.projectInfo.lwgs = workerinfo.company_name;
        this.projectInfo.lwbz = workerinfo.workersubcontractor_name;
        this.projectInfo.jcrq = workerinfo.in_time;
        this.projectInfo.tcrq = workerinfo.out_time;

        this.initData = workerinfo;
        let educations_time = 0;
        if (workerinfo.education instanceof Array) {
          var education = workerinfo.education;
          for (let index = 0; index < education.length; index++) {
            const element = education[index];
            educations_time = educations_time + element.educations_time;
          }
        }
        this.userInfo.educations_time = educations_time;
        return;
      }
    }

    //加载初始化数据
    axios({
      method: "get",
      url:
        "https://safety-2343161-1300856884.ap-shanghai.run.tcloudbase.com/api/education/workerinfo/?keys=" +
        keys,
    }).then((res) => {
      if (res.data.code != 2000 || res.data.data.total < 1) {
        Toast.fail("查无此人");
        return;
      }
      let result = res.data.data.data[0];
      this.userInfo.name = result.name;
      this.userInfo.sex = result.gender;
      this.userInfo.age = result.birthday;
      this.userInfo.workType = result.work_type_name;
      this.userInfo.status = result.status;
      let currentYear = new Date().getFullYear();
      this.userInfo.ageNum = result.birthday.split("-")[0]
        ? currentYear - result.birthday.split("-")[0]
        : null;

      this.projectInfo.name = result.dept_name;
      this.projectInfo.lwgs = result.company_name;
      this.projectInfo.lwbz = result.workersubcontractor_name;
      this.projectInfo.jcrq = result.in_time;
      this.projectInfo.tcrq = result.out_time;

      this.initData = result;
      sessionStorage.setItem("workerinfo", JSON.stringify(this.initData));
      let educations_time = 0;
      if (result.education instanceof Array) {
        var education = result.education;
        for (let index = 0; index < education.length; index++) {
          const element = education[index];
          educations_time = educations_time + element.educations_time;
        }
      }
      this.userInfo.educations_time = educations_time;
    });
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .userinfo {
    padding: 20px 0 30px;
    background: #fff;
    margin-bottom: 20px;
  }
  .nameList {
    display: flex;
    padding: 5px 16px;
    justify-content: space-between;
    .name {
      .value {
        font-size: 18px;
        font-weight: 800;
      }
    }
    .statusTime {
      margin-left: auto;
      margin-right: 10px;
    }
    .status {
      background-color: #35c042;
      color: #fff;
      border-radius: 3px;
      font-size: 14px;
      padding: 2px 4px;
    }
    .warn {
      background: #e6a23c;
      color: #fff;
    }
  }
  .userinfoList {
    display: flex;
    padding: 5px 16px;
    flex-flow: wrap;
    .lable {
      color: #646566;
    }
    .sex,
    .workType {
      width: 50%;
      text-align: left;
    }
    .age {
      width: 50%;
      text-align: left;
      margin-top: 15px;
    }
  }

  .projectInfo {
    margin-bottom: 20px;
  }
}
</style>

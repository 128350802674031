<template>
  <div class="studyList">
    <div class="projectInfo" v-for="(item, index) in studyList" :key="index">
      <van-cell-group inset>
        <van-field
          label="培训名称："
          class="fileWrap"
          style="color: #4994df"
          :value="item.name"
          readonly
        />
        <van-field
          label="培训项目："
          class="fileWrap"
          style="color: #4994df"
          :value="item.dept_name"
          readonly
        />
        <van-field
          label="教育类型："
          class="fileWrap"
          style="color: #4994df"
          :value="item.type"
          readonly
        />
        <van-field
          label="培训学时："
          class="fileWrap"
          style="color: #4994df"
          :value="item.educations_time+'小时'"
          readonly
        />
        <van-field
          label="培训老师："
          class="fileWrap"
          style="color: #4994df"
          :value="item.teacher"
          v-if="direction[index] == 'up'"
          readonly
        />
        <van-field
          label="培训单位："
          class="fileWrap"
          style="color: #4994df"
          :value="item.educations_company"
          v-if="direction[index] == 'up'"
          readonly
        />
        <van-field
          label="培训地点："
          class="fileWrap"
          style="color: #4994df"
          :value="item.location"
          v-if="direction[index] == 'up'"
          readonly
        />
        <van-field
          label="培训内容："
          class="fileWrap"
          style="color: #4994df"
          :value="item.content"
          v-if="direction[index] == 'up'"
          readonly
        />
        <div class="imgListWrap" >

        
        <van-field
          label="培训照片："
          readonly
            :value="item.image_url?'':'暂无图片'"
            :style="{width:item.image_url?'30%':'100%'}"
          v-if="direction[index] == 'up'"
        />
        <div
          class="imgList"
          v-show="item.image_url"
          v-if="direction[index] == 'up'"
        >
          <van-image
            width="100"
            height="100"
            v-for="(item, index) in item.image_url"
            :key="index"
            :src="item+'&imageMogr2/thumbnail/900x'" 
            style="margin-right: 5px"
            @click="showImg(item)"
          />
        </div>
    </div>
        <!-- <div v-show="!item.image_url" v-if="direction[index] == 'up'">
          暂无照片
        </div> -->

        <van-field
          label="培训记录："
          class="fileWrap"
          style="color: #4994df"
          :value="item.filename"
          readonly
          @click="downLoad(item.file_url)"
          v-show="direction[index] === 'up'"
        />
        <van-cell
          title="培训时间："
          :value="item.time"
          is-link
          :arrow-direction="direction[index]"
          @click="showmany(index)"
        />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ImagePreview } from "vant";
export default {
  name: "studyList",
  components: {},
  data() {
    return {
      direction: [],
      studyList: [
        {
          dept_name: "",
          name: "",
          time: "",
          image_url: [],
        },
      ],
    };
  },
  methods: {
    //文件下载
    downLoad(file_url) {
      window.open(file_url);
    },
    //图片雨量
    showImg(item) {
      ImagePreview([item || "https://img01.yzcdn.cn/vant/apple-1.jpg"]);
    },
    showmany(index) {
      if (this.direction[index] == "up") {
        this.direction[index] = "down";
      } else {
        this.direction[index] = "up";
      }
      console.log(this.direction);
      this.$forceUpdate();
    },
  },
  mounted() {
    this.studyList =
      this.$route.params.data ||
      JSON.parse(sessionStorage.getItem("education"));

    const studyList = this.studyList;
    if (studyList instanceof Array) {
      console.log(1111,studyList);
      for (let index = 0; index < studyList.length; index++) {
        this.direction.push("down");
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.studyList {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .userinfo {
    padding: 20px 0 30px;
    background: #fff;
    margin-bottom: 20px;
  }
  .nameList {
    display: flex;
    padding: 5px 16px;
    justify-content: space-between;

    .warn {
      background: #e6a23c;
      color: #fff;
    }
  }
  .userinfoList {
    display: flex;
    // justify-content: space-around;
    padding: 5px 16px;
    .sex,
    .workType,
    .age {
      width: 30%;
      text-align: left;
    }
  }

  .projectInfo {
    margin-bottom: 20px;
  }
  .imgListWrap{
    display: flex;
    .imgList{
    //  width: 80%;
    flex:1
    }
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Field } from 'vant';
import { Cell } from 'vant';
import { Image as VanImage } from 'vant';
import { CellGroup } from 'vant';

import 'vant/lib/index.css';
Vue.use(Field);
Vue.use(Cell);
Vue.use(VanImage);

Vue.use(CellGroup);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
